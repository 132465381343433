<template>
  <base-popover
    v-show="auth.loggedIn && details"
    v-slot="{ close, open, opened, toggle }"
    :close-on-leave="closeOnLeave"
    placement="bottom-end"
  >
    <base-button
      aria-controls="loyalty-rewards-info-box"
      :aria-expanded="opened"
      :class="brandClasses.rewardsPreviewTileButton"
      @click="toggle"
      @keydown.esc="close"
      @mouseenter="openOnHover ? open($event) : undefined"
    >
      <span class="pointer-events-none flex gap-2 ws-nowrap" style="max-width: 15rem">
        <span class="truncate" style="max-width: 6.25rem">
          {{ greeting }}
        </span>
        <span class="fw-bold">
          {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: loyalty.currentReward }) }}
        </span>
      </span>
    </base-button>
    <base-popover-content :id :class="brandClasses.rewardsPreviewTilePopoverContent" @keydown.esc="close">
      <div :class="brandClasses.rewardsPreviewTilePopoverContentInner" data-test-id="rewards-info-box">
        <div class="text-md">
          {{ greeting }}
        </div>
        <div class="mt-1 truncate text-base fw-bold">
          {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: loyalty.currentReward }) }}
        </div>
        <loyalty-rewards-details v-if="loyalty.vouchers" :vouchers="loyalty.vouchers" />
      </div>
    </base-popover-content>
  </base-popover>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    closeOnLeave?: boolean
    openOnHover?: boolean
  }>(),
  {
    closeOnLeave: false,
    openOnHover: false
  }
)

const { brandClasses } = useAppConfig().components.vf.loyalty
const auth = useAuthStore()
const id = useId()
const loyalty = useLoyaltyStore()
const { $t } = useNuxtApp()
const { details } = storeToRefs(useUserStore())

const greeting = computed(() =>
  details.value?.firstName
    ? replaceAll($t.loyaltyRewardsDetails.greetingWithName, { name: details.value.firstName })
    : $t.loyaltyRewardsDetails.greeting
)

watch(() => auth.loggedIn, () => {
  if (auth.loggedIn && !loyalty?.vouchers) loyalty.getVouchers()
}, { immediate: true })
</script>
